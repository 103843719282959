<template>
  <div>  
    <sideBar />    
  </div>
</template>

<script>

import {defineAsyncComponent, reactive} from 'vue'
import sideNav from '@/components/SideNav'

export default {
  name: 'Home',
  setup() {        
    return {
      
    }
  },
  components: {
     sideBar : defineAsyncComponent(() => import(/* webpackChunkName: "sidebar" */ '@/components/Sidebar')),
     sideNav
  }
}
</script>

<style scope>
  
  

</style>