import ndgApi from '@/api/ndgApi'

export const crearUsuario = async({commit}, usuario) => {
    
}

export const iniciarSesion = async({commit}, credencialess) => {
    try {        
        const {data} = await ndgApi.post('Login', credencialess)
        const {Objeto} = data
        console.log(Objeto);
        let user = Objeto.sesion
        let token = user.token
        commit('loginUser', {user, token})        
        return { tipo : true, mensaje: 'Inicio sesión correcto' }
    } catch (error) {
        console.log("error al realizar inicio de sesion : " + JSON.stringify(error.response.data));
        return { tipo : false, mensaje: error.response.data.Mensaje }
    }
}