<template>
    <div>

        <div class="sidebar ">
            <div class="logo-details">
                <i class='bx bxl-c-plus-plus'></i>
                <span class="logo_name">CodingLab</span>
            </div>
            <ul class="nav-links">
                <li>
                    <a href="#">
                        <i class='bx bx-home'></i>
                        <span class="link_name">Inicio</span>
                    </a>
                     <ul class="sub-menu blank">
                        <li><a class="link_name" href="#">Inicio</a></li>
                    </ul>
                </li>
                <li>
                    <div class="iocn-link">
                        <a href="#">
                            <i class='bx bx-file' ></i>
                            <span class="link_name">Documentos</span>
                        </a>
                        <i class='bx bxs-chevron-down arrow' ></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Documentos</a></li>
                        <li><a href="#">Ordenes de compra</a></li>
                        <li><a href="#">Entradas de mercancías</a></li>
                        <li><a href="#">Facturas de proveedor</a></li>
                    </ul>
                </li>
                <li>
                    <div class="iocn-link">
                        <a href="#">
                            <i class='bx bx-stats'></i>
                            <span class="link_name">Cuentas</span>
                        </a>
                        <i class='bx bxs-chevron-down arrow' ></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Estados cuentas</a></li>
                        <li><a href="#">Pagos efectuados</a></li>
                    </ul>
                </li>
                <li>
                    <div class="iocn-link">
                        <a href="#">
                            <i class='bx bx-certification'></i>
                            <span class="link_name">Certificados</span>
                        </a>
                        <i class='bx bxs-chevron-down arrow' ></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Certificados</a></li>
                        <li><a href="#">Certificado de retenciones</a></li>
                    </ul>
                </li>
                <li>
                    <div class="iocn-link">
                        <a href="#">
                            <i class='bx bx-slider-alt'></i>
                            <span class="link_name">Administrar</span>
                        </a>
                        <i class='bx bxs-chevron-down arrow' ></i>
                    </div>
                    <ul class="sub-menu">
                        <li><a class="link_name" href="#">Administración</a></li>
                        <li><a href="#">Cambiar contraseña</a></li>
                        <li><a href="#">Acuerdo confidencialidad</a></li>
                    </ul>
                </li>    
                <li>
                    <div class="profile-datails">
                        <div class="profile-content" >
                            <img src="@/assets/images/profile.jpg" alt="Perfil">
                        </div>
                        <div class="name-job" >
                            <div class="profile_name">Prem Shani</div>
                            <div class="job">Web desing</div>
                        </div>
                        <i class='bx bx-log-out-circle'></i>
                    </div>
                </li>            
            </ul>            

        </div>

        <section class="home-section">
            <div class="home-content">
                <i class='bx bx-menu'></i>
                <span class="text">Drop Dowm Sidebar</span>
            </div>
        </section>

        
    </div>
</template>

<script>
import { onMounted } from "vue";

export default {
    name:'SideNav',
    setup(props) {

        const iniciarMenu = () => {
            let arow = document.querySelectorAll('.arrow');
            for(var i=0; i < arow.length; i++){
                arow[i].addEventListener("click", (e) => {
                    let arowParent = e.target.parentElement.parentElement;
                    arowParent.classList.toggle("showMenu");                    
                })
            }

            let sideNav = document.querySelector('.sidebar');
            let sideNavBtn = document.querySelector('.bx-menu');
            sideNavBtn.addEventListener("click", (e) => {
                sideNav.classList.toggle("close");
            })
        }

        onMounted(iniciarMenu);

        return {

        }

    }
}
</script>

<style scoped>
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.sidebar{
    position: fixed; 
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background-color: var(--ng-fondo); 
    z-index: 100;
    transition: all 0.5s ease;
}
.sidebar.close{
    width: 78px;
}
.sidebar .logo-details{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}
.sidebar .logo-details i{
    font-size: 30px;
    color: #434142;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
}
.sidebar .logo-details .logo_name{
    font-size: 22px;
    color: #434142;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name{
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links {
    height: 100%;
    padding: 30px 0 150px 0;
    overflow: auto;
}
.sidebar.close .nav-links {
    overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
    display: none;
}
.sidebar .nav-links li{
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}
.sidebar .nav-links li:hover{
    background: var(--ng-primario);
    color: var(--ng-blanco);
}
.sidebar .nav-links .showMenu .sub-menu{
    background: var(--ng-fondo);
    margin-top: 0px ;
    /*color: var(--ng-primario);*/
}
.sidebar.close .nav-links .showMenu .sub-menu{
    background: var(--ng-primario);
    color: var(--ng-blanco);
}
.sidebar .nav-links li .iocn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link {
    display: block;
}
.sidebar .nav-links li i{
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color:  #434142;
    font-size: 20px;
    transition: all 0.3s ease;    
    cursor: pointer;
}
.sidebar .nav-links li:hover i {
    color: var(--ng-blanco);
}
.sidebar .nav-links li.showMenu i.arrow{
    transform: rotate(180deg);
}
.sidebar.close .nav-links i.arrow{
    display: none;
}
.sidebar .nav-links li a {
    display: flex;
    align-items: center;    
    text-decoration: none;
}
.sidebar .nav-links li a .link_name {
    font-size: 18px;
    font-weight: 400;
    color: #434142;
}
.sidebar .nav-links li:hover a .link_name {
    color: var(--ng-blanco);
}
.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}
.sidebar .nav-links li .sub-menu{
    padding: 6px 6px 14px 60px;
    margin-top: -10px;
    /*background: #075FFE;*/
    display: none;
}
.sidebar.close .nav-links li .sub-menu{
    padding: 6px 6px 14px 60px;
    margin-top: -10px;
    background: var(--ng-primario);
    display: none;
}
.sidebar .nav-links li.showMenu .sub-menu{
    display: block;
}
.sidebar .nav-links li .sub-menu a{
    color: #434142;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 1;
    transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
    color: var(--ng-blanco);
    background: var(--ng-primario);
    padding-left: 16px;
    border-radius: 6px;
}

.sidebar.close .nav-links li .sub-menu{
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0px;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    transition: all 0.4s ease; 
    opacity: 0;
    display: block;
    pointer-events: none;
}
.sidebar.close .nav-links li:hover .sub-menu {
    top: 0;    
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name{
    display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
    display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
    font-size: 18px;
    opacity: 1;
    display: block;
}
.sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%)
}

.sidebar .profile-datails{
    width: 260px;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--ng-fondo); 
    padding: 6px 0;
    transition: all 0.4s ease;
}
.sidebar.close .profile-datails {
    width: 78px;
}
.sidebar.close .profile-datails {
    background: none;
}
.sidebar .profile-datails .profile-content{
    display: flex;
    align-items: center;

}
.sidebar .profile-datails img{
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #434142;   
    transition: all 0.5s ease; 
}
.sidebar.close .profile-datails img{
    padding: 10px;
}
.sidebar .profile-datails .profile_name,
.sidebar .profile-datails .job {
    color: #434142;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}
.sidebar.close .profile-datails i,
.sidebar.close .profile-datails .profile_name,
.sidebar.close .profile-datails .job {
    display: none;
}
.sidebar .nav-links li:hover .profile-datails i,
.sidebar .profile-datails i:hover{
    color: #075FFE !important;
}
.sidebar .profile-datails .job{
    font-size: 12px;
}


.home-section{
    position: relative;
    background-color: var(--ng-fondo); 
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
}
.sidebar.close ~ .home-section{
    left: 78px;
    width: calc(100% - 78px);
}
.home-section .home-content{
    height: 60px;
    display: flex;
    align-items: center;
}
.home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
    color: #000000;
    font-size: 36px;
}
</style>