import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'datatables.net-bs5' 
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' 
import 'datatables.net-buttons-bs5' 
import 'datatables.net-buttons/js/buttons.colVis.js' 
import 'datatables.net-buttons/js/buttons.html5.js' 
import 'datatables.net-buttons/js/buttons.print.js' 
import 'jszip' 
import 'pdfmake' 

import  { Button, Alert, Switch, Input, Icon, } from 'equal-vue'
import 'equal-vue/dist/style.css'

import {create, NConfigProvider, NButton, NInput, NGrid, NGridItem, NSpace, NDataTable, NTag} from 'naive-ui'



const app = createApp(App)

const naive = create({
    components : [NConfigProvider, NButton, NInput, NGrid, NGridItem, NSpace, NDataTable, NTag]
})

app.use(naive)
app.use(Button).use(Input).use(Icon)

app.use(VueSweetalert2)

app.use(store).use(router).mount('#app')
