import axxios from 'axios'
import apiSetting from '@/assets/js/utilidades/apiSetting.json'

const settingApi = JSON.parse(JSON.stringify(apiSetting));

const ndgApi = axxios.create({
    baseURL : settingApi.url_base,   
    timeout: 15000,
    responseType : 'json',    
    headers: {
        'Content-Type': 'application/json; charset=UTF-8'
    },
})

export default ndgApi