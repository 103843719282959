<template>
    <div>
        <router-link :to="to" :class="{ activado : isActive }" >
            <i :class='icon'></i>
            <slot />
        </router-link>
    </div>
</template>

<script>

import { useRoute }  from 'vue-router'
import { computed }  from 'vue'

export default {
    name : 'Sidebar link',
    props : {
        to: { type: String, required: true },
        icon: { type: String, required: true }
    },
    setup(props) {
        var route = useRoute()
        const isActive = computed(() => {
            return route.path === props.to
        })
        return {
            isActive
        }
    }
}
</script>

<style scope>
    .activado{
        border-radius: 10px;
        background: #0d6efd;
        color: #ffffff !important;
    }
</style>