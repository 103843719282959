import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "inicio" */ '../views/inicio/Inicio.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginPage.vue'),
    children : [
      {
        path: '',
        name: 'ingresar',
        component: () => import(/* webpackChunkName: "Ingresar" */ '@/components/auth/Login.vue')
      },
      {
        path: '/registrar',
        name: 'registrar',
        component: () => import(/* webpackChunkName: "Registrase" */ '@/components/auth/Registrar.vue')
      }
    ]
  },
  {
    path: '/ordencompra',
    name: 'Orde compra',
    component: () => import(/* webpackChunkName: "orden compra" */ '../views/documentos/OrdeCompra.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/ordencompra/:detalle',
    name: 'Detalle orde compra',
    component: () => import(/* webpackChunkName: "detalle orden compra" */ '../views/documentos/OrdenCompraDetalle.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/mercancias',
    name: 'Entrada mercancia',
    component: () => import(/* webpackChunkName: "entrada mercancia" */ '../views/documentos/EntradaMercancia.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/mercancias/:detalle',
    name: 'Detalle entrada mercancia',
    component: () => import(/* webpackChunkName: "detalle entrada mercancia" */ '../views/documentos/EntradaMercanciaDetalle.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/facturas',
    name: 'Factura provedor',
    component: () => import(/* webpackChunkName: "facturas" */ '../views/documentos/FacturasProveedor.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/facturas/:detalle',
    name: 'Detalle factura provedor',
    component: () => import(/* webpackChunkName: "detalle facturas" */ '../views/documentos/FacturasProveedorDetalle.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/pagos',
    name: 'Pagos efectuados',
    component: () => import(/* webpackChunkName: "pagos" */ '../views/estadosdecuentas/Pagos.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/retencion',
    name: 'Certificado retencion',
    component: () => import(/* webpackChunkName: "retencion" */ '../views/certificados/CertificadoRetencion.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/cambiar_clave',
    name: 'Cambiar clave',
    component: () => import(/* webpackChunkName: "cabiar clave" */ '../views/administracion/CambiarClave.vue'),
    meta: {requiresAuth : true}
  },
  {
    path: '/confidencialidad',
    name: 'Acuerdo confidencialidad',
    component: () => import(/* webpackChunkName: "confidencialidad" */ '../views/administracion/AcuerdoConfidencialidad.vue'),
    meta: {requiresAuth : true}
  },

  {    
    path: '/:pathMatch(.*)*' , 
    component: () => import(/* webpackChunkName: "notfound" */ '../views/administracion/NotFoundPage.vue'),
    meta: {requiresAuth : true} 
  }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  mode: history,
  routes
})

router.beforeEach((to, from, next) => {
  let logeado = localStorage.getItem('idToken');
  let requiereAutorizacion = to.matched.some(record => record.meta.requiresAuth);

  if(requiereAutorizacion && logeado == null){
    next({path:'/login'})
  }else {
    next()
  }
})

export default router
