<template>
  <div class="contenedor-general">
    <div v-if="isLogeado" class="sidebar">
      <span v-if="opt_cerrar" class="span-ocultar" @click="cerraMenu">
        <i class="bx bx-chevrons-left"></i>
      </span>

      <div class="box-nav-content" :style="{ display: contentWidthState }">
        <div>
          <img class="logo-empresa" src="../assets/images/img_logo.png" alt="" />
          <h6 class="nombre-empleado">Néstor Duarte Garcia</h6>
          <small class="cargo">Proveedor</small>
        </div>

        <div class="sidebar-nav">
          <ul>
            <li><sidebarLink to="/" icon="bx bx-home">Inicio</sidebarLink></li>
            <li>
              <a
                data-bs-toggle="collapse"
                href="#ldocumentos"
                aria-controls="ldocumentos"
              >
                <i class="bx bx-file"></i>
                Documentos
                <i class="bx bx-chevron-down izquierda"></i>
              </a>
            </li>
            <div class="collapse" id="ldocumentos">
              <ul>
                <li>
                  <sidebarLink to="/ordencompra" icon="bx bx-chevron-right"
                    >Orden de compra</sidebarLink
                  >
                </li>
                <li>
                  <sidebarLink to="/mercancias" icon="bx bx-chevron-right"
                    >Entradas de mercancías</sidebarLink
                  >
                </li>
                <li>
                  <sidebarLink to="/facturas" icon="bx bx-chevron-right"
                    >Facturas de proveedor</sidebarLink
                  >
                </li>
              </ul>
            </div>

            <li>
              <a
                data-bs-toggle="collapse"
                href="#lestadoscuentas"
                aria-controls="lestadoscuentas"
              >
                <i class="bx bx-stats"></i>
                Estados de cuentas
                <i class="bx bx-chevron-down izquierda"></i>
              </a>
            </li>
            <div class="collapse" id="lestadoscuentas">
              <ul>
                <li>
                  <sidebarLink to="/pagos" icon="bx bx-chevron-right"
                    >Pagos efectuados</sidebarLink
                  >
                </li>
              </ul>
            </div>

            <li>
              <a
                data-bs-toggle="collapse"
                href="#lcertificados"
                aria-controls="lcertificados"
              >
                <i class="bx bx-certification" />Certificados<i
                  class="bx bx-chevron-down izquierda"
                />
              </a>
            </li>
            <div class="collapse" id="lcertificados">
              <ul>
                <li>
                  <sidebarLink to="/retencion" icon="bx bx-chevron-right"
                    >Certificado de retenciones</sidebarLink
                  >
                </li>
              </ul>
            </div>

            <li>
              <a
                data-bs-toggle="collapse"
                href="#ladministracion"
                aria-controls="ladministracion"
              >
                <i class="bx bx-slider-alt" /> Administración
                <i class="bx bx-chevron-down izquierda" />
              </a>
            </li>
            <div class="collapse" id="ladministracion">
              <ul>
                <li>
                  <sidebarLink to="/cambiar_clave" icon="bx bx-chevron-right"
                    >Cambiar contraseña</sidebarLink
                  >
                </li>
                <li>
                  <sidebarLink to="/confidencialidad" icon="bx bx-chevron-right"
                    >Acuerdo confidencialidad</sidebarLink
                  >
                </li>
              </ul>
            </div>
            <li>
              <a @click="salirApp()"><i class="bx bx-log-out-circle"></i> Salir</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="page-content-wrapper">
      <router-view />
    </div>
  </div>

  <!--  <div class="footer">
        <p>Copyright © 2021 NEDUGA TECH S.A.S  <a href="https://nedugatech.com" target="_blank" > nedugatech.com </a></p>
    </div> -->
</template>

<script>
import stateSidebar from "@/composable/stateSidebar";
import sidebarLink from "../components/SidebarLink";
import { useRouter, useRoute } from "vue-router";
import { onMounted, onUnmounted, watch, ref, computed } from "vue";

export default {
  name: "Sidebar",
  setup() {
    const isLogeado = ref(true);
    const opt_cerrar = ref(false);

    const router = useRouter();
    const {
      colapsado,
      toggleSidebar,
      sideBarWidthState,
      contentWidthState,
    } = stateSidebar();

    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    watch(windowWidth, (count, prevCount) => {
      console.log("Cambio tamaño dispositivo : " + windowWidth.value);
      if (windowWidth.value < 700) {
        cerraMenu();
        opt_cerrar.value = true;
      } else {
        let sideNav = document.querySelector(".sidebar");
        sideNav.classList.remove("close");
        opt_cerrar.value = false;
      }
    });

    const cerraMenu = () => {
      let sideNav = document.querySelector(".sidebar");
      if (sideNav != null) {
        sideNav.classList.add("close");
      }
    };

    const validarTamanio = () => {
      let sideNav = document.querySelector(".sidebar");
      if (windowWidth.value < 700) {
        sideNav.classList.add("close");
        opt_cerrar.value = true;
      }
    };

    const salirApp = () => {
      localStorage.removeItem("idToken");
      isLogeado.value = false;
      router.push({ path: "/login" });
    };

    onMounted(() => {
      window.addEventListener("resize", onWidthChange);
      validarTamanio();
      const token = localStorage.getItem("idToken");
      if (token == null || token == "") {
        isLogeado.value = false;
      }
    });

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    return {
      colapsado,
      toggleSidebar,
      sideBarWidthState,
      contentWidthState,
      salirApp,
      isLogeado,
      cerraMenu,
      opt_cerrar,
    };
  },
  components: {
    sidebarLink,
  },
};
</script>

<style scope>
.contenedor-general {
  display: flex;
  flex-flow: row;
  background-color: var(--ng-fondo);
}
.sidebar {
  width: 300px;
  background: var(--ng-fondo);
  float: left;
  /*position: fixed;*/
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--ng-grisdark);
  /*padding-bottom: 1.8rem;*/
  transition: all 0.5s ease;
  position: sticky;
}

.sidebar.close {
  width: 0px;
}

.sidebar .nombre-empleado {
  margin: 0px;
  font-weight: bold;
  opacity: 1;
  font-size: 16px;
  transition: 0.1s ease;
  transition-delay: 0.3s;
}
.sidebar .cargo {
  transition: 0.1s ease;
  transition-delay: 0.3s;
}
.sidebar.close .nombre-empleado,
.sidebar.close .cargo {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.page-content-wrapper {
  /*width: 100%;
        position: absolute;
        transition: all 0.3s ease-out;
        height: 100%;
        padding: 2rem 2rem 2.8rem 0rem;*/
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.box-nav-content {
  transition: all 0.3s ease-out;
  overflow: auto;
}

.box-nav-content::-webkit-scrollbar {
  display: none;
}

.span-ocultar {
  display: block;
  position: absolute;
  left: 0px;
  top: -5px;
  transition: all 0.5s ease;
}

.sidebar.close .span-ocultar {
  transition: all 0.5s ease;
  display: none;
}

.span-ocultar i {
  font-size: 2em;
  color: var(--ng-terciario);
}

.logo-empresa {
  width: 100%;
  padding: 1rem;
}

.sidebar-nav {
  padding: 0;
  list-style: none;
  margin-top: 2rem;
}

.sidebar-nav ul {
  text-decoration: none;
  list-style: none;
  text-align: left;
  padding-left: 0.4rem;
}

.sidebar-nav li {
  text-indent: 10px;
  line-height: 40px;
  margin: 3px 0 3px 0;
  transition: 0.2s ease;
  transition-delay: 0.3s;
}
.sidebar.close li {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar-nav li a {
  text-decoration: none;
  color: var(--ng-grisclaro);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.sidebar-nav li a i {
  font-size: 1.3em;
  text-align: left;
}

.sidebar-nav li a:hover {
  border-radius: 10px;
  background: #0d6efd;
  color: #ffffff !important;
}

.activado {
  background: #0d6efd;
}

.izquierda {
  /*position: absolute;
        left: 14rem;*/
}

.footer {
  background-color: #dce1f2;
  height: 1.8rem;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 0.78em;
  font-weight: bold;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

@media (max-width: 676px) {
  .sidebar {
    position: fixed;
  }
  .page-content-wrapper {
    padding-left: 0px !important;
  }
}
</style>
