<template>
  <Sidebar />
</template>

<script>
//import themaPersonal from '@/composable/useCustomTheme'
import Sidebar from "@/components/Sidebar";
//import stateSidebar from '@/composable/stateSidebar'
import Home from "./views/inicio/Home";
import { onMounted, onUpdated, ref, computed } from "vue";

export default {
  setup() {
    const logeado = ref(false);
    const estaLogeado = computed(
      () => (logeado.value = localStorage.getItem("idToken") != null)
    );

    return {
      logeado,
      //themaPersonal,
      //sideBarWidthState,
    };
  },
  components: {
    Home,
    Sidebar,
  },
};
</script>

<style></style>
