
import {ref, computed} from 'vue'

export default function(){

    const colapsado = ref(false)
    const toggleSidebar = () => {
        //console.log("cambiando valor");
        colapsado.value = !colapsado.value
    }

    const sidebarWidth = 290
    const sidebar_colapsado_width = 0

    const sideBarWidthState = computed(() => {
        //console.log("computando valor : " + `${colapsado.value ? sidebar_colapsado_width : sidebarWidth }px`);
        return `${colapsado.value ? sidebar_colapsado_width : sidebarWidth }px`
    })

    const contentWidthState = computed(() => {
        //console.log("computando valor : " + `${colapsado.value ? sidebar_colapsado_width : sidebarWidth }px`);
        return `${colapsado.value ? 'none' : 'block' }`
    })

    
    return {
        colapsado, toggleSidebar, sideBarWidthState, contentWidthState
    }

}